import React from 'react'
import { FormattedMessage } from 'react-intl'
import Link from 'next/link'
import styled from 'styled-components'
import { PageContainer } from '../components/layout'
import { PageTitle } from '../components/common'
import { breakpoints } from '../theme/breakpoints'
import routes from '../core/application/routes'

const NotFoundPage = () => {
  return (
    <PageContainer>
      <PageTitle>
        <FormattedMessage id='NotFoundPage.PageTitle' />
      </PageTitle>
      <PageWrapper>
        <FormattedMessage id='NotFoundPage.PageWrapper.Text' />
        <LinkWrapper>
          <Link href={routes.INDEX} replace>
            <a>
              <FormattedMessage id='NotFoundPage.PageWrapper.GoBackLink' />
            </a>
          </Link>
        </LinkWrapper>
      </PageWrapper>
    </PageContainer>
  )
}

const PageWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 0.4rem;
  border: solid 1px ${({ theme }) => theme.color.lightGrey};
  font-weight: ${({ theme }) => theme.weight.semi};
  font-size: ${({ theme }) => theme.size.normal};
  color: ${({ theme }) => theme.color.black};
  border-bottom: solid 1px #e9e9e9;
  padding: 5rem;
  text-align: center;
  
  max-width: 40rem;

  @media ${breakpoints.tablet} {
    padding: 2.7rem 4.9rem;
  }


`

const LinkWrapper = styled.div`
  margin-top: 1rem;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.color.primary};
  }
`

export default NotFoundPage
